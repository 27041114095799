/*
Author -- Jesse Pelletier
 */

/**
 * Markdown body of homepage
 * @type {string}
 */
const ReadMeBody =
    "# Welcome to Markdown Explorer!\n" +
    "\n" +
    "### Adding SOPs\n" +
    "Click the **+** Icon to upload your SOP document. (Markdown files only).\n" +
    "\n" +
    "### Deleting SOPs\n" +
    "Either select an SOP and click the trash icon OR use **Microsoft Azure Storage Explorer** to delete documents on the list to the left.  Once you've deleted your file, hit the refresh button.\n" +
    "\n" +
    "- Subscription -- **dil-prod**\n" +
    "- Storage Account -- **mdstore**\n" +
    "- Blob Container -- **sops**";

export default ReadMeBody