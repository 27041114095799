/*
* Author -- Jesse Pelletier
* */
import * as React from "react";
import Box from "@mui/material/Box";
import { Avatar, Button, Container } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import LaunchIcon from "@mui/icons-material/Launch";

/**
 * View that is shown when the user has not been authenticated yet
 * @param props
 * @returns {Element}
 * @constructor
 */
const UnauthenticatedPage = (props) => {
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in to Azure
                </Typography>
                <Box
                    component="form"
                    onSubmit={props.handleLogin}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 2 }}
                    >
                        <LaunchIcon />
                        <Box px={2}>Sign In</Box>
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default UnauthenticatedPage;
