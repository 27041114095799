/* eslint-disable */
import {TreeItem} from "@mui/x-tree-view";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import * as React from "react";
import {alphabeticalFn, newestFn, oldestFn, revAlphabeticalFn} from "./CompareFunctions";
import {InteractiveBrowserCredential} from "@azure/identity";
import {BlobServiceClient} from "@azure/storage-blob";

/*
Author -- Jesse Pelletier
 */
//: AZURE BLOB CLIENT FUNCTIONS
async function getChildren(containerClient, prefix) {
    let localBlobList = [];
    for await (const blob of containerClient.listBlobsByHierarchy('/', {prefix: prefix})) {
        // and plunk them in a local array...
        if (blob.kind === 'prefix') {
            localBlobList.push({
                name: blob.name,
                fullPath: blob.name,
                body: null,
                lastModified: null,
                children: await getChildren(containerClient, blob.name)
            })
        } else {
            const currBlobClient = containerClient.getBlobClient(blob.name);
            await currBlobClient
                .download()
                .then((res) => res.blobBody)
                .then((body) => body.text())
                .then((res) => {
                    localBlobList.push({
                        name: blob.name.replace(prefix, ""),
                        fullPath: blob.name,
                        body: res,
                        lastModified: blob.properties.lastModified,
                        children: null
                    });
                });
        }
    }

    return localBlobList;
}

export async function getBlobs(setMarkdownList, sortingMethod, setContainerClient, setIsAuthenticated) {
    const credential = new InteractiveBrowserCredential({
        clientId: "5f6d3e88-1ad6-4ea3-938a-b8dc82c02a11",
        tenantId: "54e2aef7-7790-4af2-957b-95f462b78dc7",
        loginStyle: 'redirect'
    });

    const blobServiceClient = new BlobServiceClient(
        `https://mdstore.blob.core.windows.net/`,
        credential
    );

    const containerClient = blobServiceClient.getContainerClient("sops");
    setContainerClient(containerClient);

    let localBlobList = [];
    // now let's query our container for some blobs!
    for await (const blob of containerClient.listBlobsByHierarchy('/')) {
        // and plunk them in a local array...
        if (blob.kind === 'prefix') {
            localBlobList.push({
                name: blob.name,
                fullPath: blob.name,
                body: null,
                lastModified: null,
                children: await getChildren(containerClient, blob.name)
            })
        } else {
            const currBlobClient = containerClient.getBlobClient(blob.name);
            await currBlobClient
                .download()
                .then((res) => res.blobBody)
                .then((body) => body.text())
                .then((res) => {
                    localBlobList.push({
                        name: blob.name,
                        fullPath: blob.name,
                        body: res,
                        lastModified: blob.properties.lastModified,
                        children: null
                    });
                });
        }
    }

    localBlobList = sortBlobList(sortingMethod, localBlobList);
    setMarkdownList(localBlobList);
}

export async function deleteMarkdown(containerClient, fullPath){
    const blockBlobClient = await containerClient.getBlockBlobClient(fullPath)

    const options = {
        deleteSnapshots:'include'
    }
    return await blockBlobClient.delete(options).then(()=>{return true}).catch(() => {return false;})

}


//: COMPONENT TRANSFORMATION FUNCTIONS
function getTreeChildren(list, prefix) {
    return list.map((item) => {
        if (item.body === null && item.lastModified === null) {
            return (<TreeItem children={getTreeChildren(item.children, item.name)} sx={{padding: '3px'}}
                              nodeId={"folder/" + item.name.replace(prefix, '').replace('/', '')}
                              label={item.name.replace(prefix, '').replace('/', '')} icon={(<FolderCopyIcon/>)}/>)
        } else {
            return (
                <TreeItem sx={{padding: '3px'}} nodeId={"blob/" + item.name.replace('/', '')} label={item.name.replace('/', '')}
                          icon={(<InsertDriveFileIcon/>)}/>)
        }
    })
}

/**
 * Converts markdownList to TreeItem components
 * @param mdList list of markdowns
 * @param setTreeItems treeItem useState setter
 */
export function markdownListToTreeItems(mdList, setTreeItems) {
    setTreeItems(mdList.map((item) => {

        //folder
        if (item.body === null && item.lastModified === null) {
            return (<TreeItem children={getTreeChildren(item.children, item.name)} sx={{padding: '3px'}}
                              nodeId={"folder/" + item.name.replace('/', '')} label={item.name.replace('/', '')}
                              icon={(<FolderCopyIcon/>)}/>)
        } else {
            return (
                <TreeItem sx={{padding: '3px'}} nodeId={"blob/" + item.name.replace('/', '')} label={item.name.replace('/', '')}
                          icon={(<InsertDriveFileIcon/>)}/>)
        }
    }));
}


//: SORTING FUNCTIONS
/**
 * Function to sort blob lists
 * @param sortingMethod {string} sorting type -- either A-Z, Z-A, oldest, or newest
 * @param list {array} blob list to sort
 * @returns {array} sorted blob list
 */
function sortBlobList(sortingMethod, list) {
    if (sortingMethod === "A-Z") {
        list.sort(alphabeticalFn);
    } else if (sortingMethod === "Z-A") {
        list.sort(revAlphabeticalFn);
    } else if (sortingMethod === "oldest") {
        list.sort(oldestFn);
    } else if (sortingMethod === "newest") {
        list.sort(newestFn);
    }

    return list;
}

/**
 * Creates copy of markdown list to sort, then sets the markdownList useState with sorted copy
 * @param markdownList list of markdowns
 * @param setMarkdownList setter function for markdownList useState
 * @param sortingMethod string representing sorting method
 */
export function sortMarkdowns(markdownList, setMarkdownList, sortingMethod) {
    let mdCopy = markdownList;
    mdCopy = sortBlobList(sortingMethod, mdCopy);
    setMarkdownList(mdCopy);
}


