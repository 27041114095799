/* eslint-disable */
/*
* Author -- Jesse Pelletier
* */

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {
    Alert, Avatar, Button,
    CircularProgress,
    IconButton,
    Modal, Snackbar,
    Tooltip,
} from "@mui/material";
import {useEffect, useState} from "react";
import markdownLogo from "../markdown.png";

import UnauthenticatedPage from "./UnauthenticatedPage";
import AuthenticatedPage from "./AuthenticatedPage";
import SettingsIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsCard from "./SettingsCard";

import FileExplorer from "./FileExplorer";
import ReadMeBody from "../enum/HomeMarkdown";
import {getBlobs, sortMarkdowns, markdownListToTreeItems, deleteMarkdown} from "../enum/MarkdownFunctions";
import DeleteDialog from "./DeleteDialog";

const defaultTheme = createTheme();

/**
 * The main view of the web app.
 * @returns {Element} returns react component
 * @constructor
 */
export default function MainWindow() {
    const [value, setValue] = useState(0);
    const [markdown, setMarkdown] = useState("");
    const [treeItems, setTreeItems] = useState([]);
    const [containerClient, setContainerClient] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [markdownList, setMarkdownList] = useState([]);
    const [showSettings, setShowSettings] = useState(false);
    const [sort, setSort] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [success, setSuccess] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [noSelectionError, setNoSelectionError] = useState(false)
    const [isFolder, setIsFolder] = useState(false)
    const [isFolderError, setIsFolderError] = useState(false)
    const [deletionSuccess, setDeletionSuccess] = useState(false)
    const [showDeletionSuccessAlert, setShowDeletionSuccessAlert] = useState(false)


    //USE EFFECTS
    /**
     * UseEffect that is called whenever the list of markdowns is changed
     * if the Markdown list isn't empty, the current markdown is set to the ReadMeBody
     * and the rest of the markdownList is converted to TreeItems for the file explorer
     */
    useEffect(() => {
        if (markdownList.length > 0) {
            markdownListToTreeItems(markdownList, setTreeItems);
            setMarkdown({body: ReadMeBody, fileType: 'md', fullPath:''});
        }
    }, [markdownList]);


    /**
     * Use effect that is called whenever the sort setting is changed
     * Resorts the markdown according to the specified methodology in the UI
     */
    useEffect(() => {
        setIsLoading(true);
        sortMarkdowns(markdownList, setMarkdownList, sort);
        markdownListToTreeItems(markdownList, setTreeItems);
        setMarkdown({body: ReadMeBody, fileType: 'md', fullPath:''});
        setIsLoading(false);
        setValue(0);
    }, [sort]);


    //MARKDOWN SELECTION FUNCTIONS
    /**
     *
     * @param target
     * @param children
     * @returns {*|boolean}
     */
    const handleSelectMarkdownHelper = (target, children) => {
        for (const md of children) {
            if (md.children != null) {
                const result = handleChangeHelper(target, md.children)
                if (result) {
                    return result
                }
            }
            if (md.name === target) {
                setMarkdown({body: md.body, fileType: md.name.split('.')[1], fullPath: md.fullPath});
                return true;
            }
        }
        return false;
    }

    /**
     *
     * @param event
     * @param newValue
     */
    const handleSelectMarkdown = (event, newValue) => {
        const selectedType = newValue.split('/')[0]

        if(selectedType === 'folder'){
            setIsFolder(true)
        }
        else{
            setIsFolder(false)
        }
        setValue(newValue);
        for (const md of markdownList) {
            if (md.name === event.target.innerText) {
                setMarkdown({body: md.body, fileType: md.name.split('.')[1], fullPath: md.fullPath});
                break;
            }
            if (md.children != null) {
                if (handleChangeHelper(event.target.innerText, md.children)) {
                    break;
                }
            }
        }
    };


    //LOGIN AND REFRESH FUNCTIONS
    /**
     * Function that is used to log in and refresh.
     * Retrieves blobs from SOPs Azure storage container.
     * @returns {Promise<void>}
     */
    const handleLogin = async () => {
        setIsLoading(true);
        await getBlobs(setMarkdownList, sort, setContainerClient, setIsAuthenticated);
        if (!isAuthenticated) {
            setIsAuthenticated(true)
        }
        setIsLoading(false);
        setValue(0);
    };


    //DELETE FUNCTIONS
    /**
     * Called when you attempt to delete a file.
     * If the selected file is a folder, an error is shown as an alert.
     * If there is nothing selected, a selection error is shown.
     * Otherwise, the delete dialog is shown.
     */
    const handleDelete = () => {
        if(isFolder){
            setIsFolderError(true)
        }
        else if(value != 0){
            setShowDeleteDialog(true)
        }
        else{
            setNoSelectionError(true)
        }
    }

    /**
     * Async function that calls the deleteMarkdown function and confirms success or failure
     * @returns {Promise<void>} like any other async function
     */
    const handleConfirm = async () => {
        setShowDeleteDialog(false)
        setIsLoading(true)
        const res = await deleteMarkdown(containerClient, markdown.fullPath)
        setDeletionSuccess(res)
        setShowDeletionSuccessAlert(true)
        await getBlobs(setMarkdownList, sort, setContainerClient, setIsAuthenticated);
        if (!isAuthenticated) {
            setIsAuthenticated(true)
        }
        setIsLoading(false)
    }

    //ALERT FUNCTIONS
    /**
     * Called to close any alerts
     */
    const handleAlertClose = () => {
        setOpenAlert(false)
    }
    /**
     * Closes the No Selection error dialog
     */
    const handleNoSelectionErrorClose = () => {
        setNoSelectionError(false)
    }

    /**
     * Closes the Folder error dialog
     */
    const handleFolderErrorClose = () => {
        setIsFolderError(false)
    }
    /**
     * Closes the Delete dialog
     */
    const handleDeleteAlertClose= () => {
        setShowDeletionSuccessAlert(false)
    }

    //MISC FUNCTIONS
    /**
     * Toggle to show settings modal
     */
    const toggleSettings = () => {
        setShowSettings(true);
    };
    /**
     * Function to set the current markdown to the ReadMeBody ("Home Markdown")
     */
    const goHome = () => {
        setMarkdown({body: ReadMeBody, fileType: 'md', fullPath:''});
        setValue(0);
    }

    /**
     * Main Window Element
     */
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            <main>

                    <Box sx={{flexGrow: 1}}>
                        <AppBar position="relative">
                            <Toolbar>
                                <Tooltip title="Home">
                                    <Button onClick={goHome}>
                                        <Avatar variant="square" src={markdownLogo}
                                                sx={{height: '30px', width: '44px'}}/>
                                    </Button>
                                </Tooltip>
                                <Typography variant="h5" component="div" sx={{paddingLeft: 1, flexGrow: 1}}>
                                    Markdown Explorer
                                </Typography>
                                {isAuthenticated && (
                                    <DeleteDialog handleDelete={handleDelete} handleConfirm={handleConfirm} open={showDeleteDialog} setOpen={setShowDeleteDialog} value={value}/>
                                )}
                                {isAuthenticated && (
                                    <FileExplorer containerClient={containerClient} handleLogin={handleLogin}
                                                  setOpenAlert={setOpenAlert} setSuccess={setSuccess}/>
                                )}
                                {isAuthenticated && (
                                    <Tooltip title="Refresh">
                                        <IconButton onClick={handleLogin} color="inherit">
                                            <RefreshIcon/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {isAuthenticated && (
                                    <Tooltip title="Settings">
                                        <IconButton onClick={toggleSettings} color="inherit">
                                            <SettingsIcon/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Toolbar>
                        </AppBar>

                        {isLoading ? (
                            <Box paddingTop={40} display="flex" justifyContent="center">
                                <CircularProgress/>
                            </Box>
                        ) : (
                            <Box>
                                {isAuthenticated ? (
                                    <Box>
                                        <Snackbar open={openAlert} autoHideDuration={5000} onClose={handleAlertClose}>
                                            {success ? (
                                                <Alert severity="success">File uploaded successfully!</Alert>) : (
                                                <Alert severity="error">Error when uploading file!</Alert>)}
                                        </Snackbar>
                                        <Snackbar open={noSelectionError} autoHideDuration={5000}
                                                  onClose={handleNoSelectionErrorClose}>
                                            <Alert severity="error">Unable to delete. No file selected.</Alert>
                                        </Snackbar>
                                        <Snackbar open={isFolderError} autoHideDuration={5000}
                                                  onClose={handleFolderErrorClose}>
                                            <Alert severity="error">Unable to delete folder. Please select file instead.</Alert>
                                        </Snackbar>
                                        <Snackbar open={showDeletionSuccessAlert} autoHideDuration={5000}
                                                  onClose={handleDeleteAlertClose}>
                                            {deletionSuccess ?
                                                (<Alert severity="success">Successfully deleted blob. </Alert>) :
                                                (<Alert severity="error">Error deleting blob. Please try again.</Alert>)
                                            }
                                        </Snackbar>
                                        <AuthenticatedPage
                                            value={value}
                                            handleSelectMarkdown={handleSelectMarkdown}
                                            treeItems={treeItems}
                                            markdown={markdown}
                                        />
                                    </Box>
                                ) : (
                                    <UnauthenticatedPage handleLogin={handleLogin}/>
                                )}
                            </Box>
                        )}

                        <Modal open={showSettings}>
                            <SettingsCard
                                setShowSettings={setShowSettings}
                                sort={sort}
                                setSort={setSort}
                                setIsLoading={setIsLoading}
                            />
                        </Modal>
                    </Box>
            </main>
        </ThemeProvider>
    );
}
