/*
* Author -- Jesse Pelletier
* */

import * as React from "react";
import Box from "@mui/material/Box";
import {Paper} from "@mui/material";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import math from "remark-math";
import rehypeHighlight from "rehype-highlight";
import rehypeRaw from "rehype-raw";
import rehypeFormat from "rehype-format";
import style from "../index.css";
import {TreeView} from "@mui/x-tree-view";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

/**
 * View that is shown when user has been authenticated to azure.
 * @param props
 * @returns {Element}
 * @constructor
 */
const AuthenticatedPage = (props) => {
    const {value, handleSelectMarkdown, treeItems, markdown} = props
    return (
        <Box
            sx={{
                height: `calc(100vh - 64px) !important`,
                bgcolor: "background.paper",
                display: "flex",
            }}
        >
            <Box marginTop={3}>

                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon/>}
                    defaultExpandIcon={<ChevronRightIcon/>}
                    selected={value}
                    onNodeSelect={handleSelectMarkdown}
                >
                    {treeItems}
                </TreeView>
            </Box>
            <Paper
                square="false"
                sx={{
                    padding: "30px",
                    width: "100%",
                    m: "10",
                    overflowY: "scroll",
                }}
            >
                {markdown.fileType === 'md' ? (<Markdown
                    className={style.markdown}
                    remarkPlugins={[gfm, math]}
                    rehypePlugins={[rehypeRaw, rehypeHighlight, rehypeFormat]}
                >
                    {markdown.body}
                </Markdown>) : (<div/>)}

            </Paper>
        </Box>
    );
};

export default AuthenticatedPage;
