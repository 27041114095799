/*
* Author -- Jesse Pelletier
* */

/**
 * Compare function for traditional alphabetical sorting
 * @param a first blob to be compared
 * @param b second blob to be compared against first nuber
 * @returns {number} 1 if a is higher alphabetically, -1 if lower, 0 if the same
 */
export function alphabeticalFn(a, b) {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
    } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
    }

    return 0
}

/**
 * Compare function to sort in reverse alphabetical
 * @param a first blob to be compared
 * @param b second blob to be compared
 * @returns {number} 1 if first blobs name is lower alphabetically, -1 if higher, 0 if the same string
 */
export function revAlphabeticalFn(a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return 1
    } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return -1
    }

    return 0
}

/**
 * Compare function to sort blob by date created, oldest first
 * @param a first blob to be compared
 * @param b second blob to be compared
 * @returns {number} 1 if blob a's last modification date is older, -1 if newer, 0 if same
 */
export function oldestFn(a, b) {
    if (a.lastModified > b.lastModified) {
        return 1
    } else if (a.lastModified < b.lastModified) {
        return -1
    }

    return 0
}

/**
 * Compare function to sort blobs by date created, newest first
 * @param a first blob to be compared
 * @param b second blob to be compared
 * @returns {number} 1 if blob a's modification date is more recent, -1 if older, 0 if the same
 */
export function newestFn(a, b) {
    if (a.lastModified < b.lastModified) {
        return 1
    } else if (a.lastModified > b.lastModified) {
        return -1
    }

    return 0
}