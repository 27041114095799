/*
* Author -- Jesse Pelletier
* */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {IconButton, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


/**
 * Transition animation when Delete dialog is closed
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<TransitionProps&{children: React.ReactElement<*, *>}> & React.RefAttributes<unknown>>}
 */
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

/**
 * Dialog to confirm a file deletion
 * @param props
 * @returns {Element}
 * @constructor
 */
const DeleteDialog = (props) => {
    const {handleDelete, handleConfirm, open, setOpen, value} = props;

    /**
     * Calls open useState hook when dialog is closed
     */
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <React.Fragment>
            <Tooltip title="Delete Selected File">
                <IconButton onClick={handleDelete} color="inherit">
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Confirm Blob Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <Typography noWrap>Are you sure you want to delete</Typography>
                            <Typography noWrap sx={{pl:1, pr: 1, fontWeight:'bold'}}>{value ? value.split('/')[1] :""}</Typography>
                            <Typography noWrap>?</Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default DeleteDialog;