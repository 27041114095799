/*
* Author -- Jesse Pelletier
* */

import * as React from 'react'
import {IconButton, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useEffect, useRef, useState} from "react";

/**
 * Uploads fileToUpload to Azure container "sops"
 * @param fileToUpload
 * @param containerClient sops container client
 * @param handleLogin passed to refresh page once upload is successful
 * @param setOpenAlert
 * @param setSuccess
 * @returns {Promise<void>}
 */
async function uploadFile(fileToUpload, containerClient, handleLogin, setOpenAlert, setSuccess){
    if(fileToUpload){
        const blockBlobClient = containerClient.getBlockBlobClient(fileToUpload.name)
        await blockBlobClient.uploadBrowserData(fileToUpload, {
            blobHTTPHeaders: {
                blobContentType: fileToUpload.type
            }}).then(() => {
                setSuccess(true)
                setOpenAlert(true)
                handleLogin()
            }).catch((e)=>{
                console.log(e)
                setSuccess(false);
                setOpenAlert(true);
            });
    }

}

/**
 * File explorer view to select file to upload to Azure
 * @param props
 * @returns {Element}
 * @constructor
 */
const FileExplorer = (props) => {
    const {containerClient, handleLogin, setOpenAlert, setSuccess} = props;
    const [fileToUpload, setFileToUpload] = useState('');

    /**
     * Hook that is called whenever the fileToUpload is selected in the fileExplorer.
     * It calls the upLoadFile function.
     */
    useEffect( () => {
        uploadFile(fileToUpload, containerClient, handleLogin, setOpenAlert, setSuccess)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileToUpload])

    const inputFile = useRef(null);

    /**
     * Called when a file is selected.  It initiates the uploading workflow.
     * @param e
     */
    const handleUpload = (e) => {
        const {files} = e.target;

        if(files && files.length){
            setFileToUpload(files[0]);
        }
    };


    const handleClick = () => {
        inputFile.current.click();
    }



    return (
    <Tooltip title="Upload Markdown">
        <input
            style={{display: 'none'}}
            ref ={inputFile}
            onChange={handleUpload}
            type='file'
        />
        <IconButton onClick={handleClick} color="inherit">
            <AddIcon />
        </IconButton>
    </Tooltip>
    )
}

export default FileExplorer;
