import './App.css';
import MainWindow from "./components/MainWindow";

function App() {
  return (
      <div>
          <MainWindow />
      </div>
  );
}

export default App;
