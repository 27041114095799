/*
* Author -- Jesse Pelletier
* */

import * as React from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Box from "@mui/material/Box";

/**
 * Card dialog where all settings are hosted.
 * @param props
 * @returns {Element}
 * @constructor
 */
const SettingsCard = (props) => {
    const { setShowSettings, sort, setSort} = props;
    const [localSort, setLocalSort] = useState(sort);

    /**
     * Sets the sorting methodology locally
     * @param e
     */
    const handleSortChange = (e) => {setLocalSort(e.target.value)};

    /**
     * Called when a change in the settings is confirmed
     * @param e save button pushed event
     */
    const saveSettings = (e) => {
        e.preventDefault();
        setShowSettings(false);
        setSort(localSort);
    };

    /**
     * SettingsCard element
     */
    return (
        <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ height: "90vh" }}
        >
            <Grid item xs={4}>
                <Card>
                    <form onSubmit={saveSettings}>
                        <CardContent
                            sx={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item>
                                    <Box paddingBottom={2}>
                                        <Typography variant="h5">Settings</Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <FormControl style={{ minWidth: 300 }}>
                                        <InputLabel>Sort By</InputLabel>
                                        <Select
                                            label="Sort By"
                                            value={localSort}
                                            onChange={handleSortChange}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            <MenuItem value="A-Z">Alphabetical (A-Z)</MenuItem>
                                            <MenuItem value="Z-A">
                                                Reverse Alphabetical (Z-A)
                                            </MenuItem>
                                            <MenuItem value="newest">Last Modified (Newest)</MenuItem>
                                            <MenuItem value="oldest">Last Modified (Oldest)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item>
                                    <Button variant="contained" type="submit">
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => {
                                            setShowSettings(false);
                                        }}
                                        color="primary"
                                        variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </form>
                </Card>
            </Grid>
        </Grid>
    );
};

export default SettingsCard;
